/**
 * Created by Giannis Kallergis on 2019-01-08
 */

import PropTypes from "prop-types";

export const ArticleNode = PropTypes.shape({
  id: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    timeToRead: PropTypes.string.isRequired,
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    tldr: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    category: PropTypes.string.isRequired,
  }).isRequired,
});

export const ArticleRef = PropTypes.shape({
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
});

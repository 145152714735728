/**
 * Created by Giannis Kallergis on 12/01/2019
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as Theme from "../../config/Theme";

const Tldr = styled.p`
  grid-column: -1 / 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${Theme.colors.grey.light};
  font-style: italic;
  span {
    color: ${Theme.colors.grey.default};
    font-weight: bold;
    font-style: normal;
  }
`;

export default class TLDR extends React.PureComponent {
  static propTypes = {
    tldr: PropTypes.string,
  };

  render() {
    const { tldr } = this.props;
    if (!tldr) {
      return null;
    }
    return (
      <Tldr>
        <span>TL;DR: </span>
        {tldr}
      </Tldr>
    );
  }
}

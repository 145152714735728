/**
 * Created by Giannis Kallergis on 2018-12-26
 */

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

/** Models / Types */
import { ArticleRef } from "../propTypes";

const Wrapper = styled.div`
  display: flex;
  margin: 3rem auto 0 auto;
  a {
    color: ${props => props.theme.colors.primary};
    display: flex;
    align-items: center;
  }
  justify-items: center;
`;

const Prev = styled.div`
  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: ${props => props.theme.colors.grey.light};
  }
`;

const Next = styled.div`
  margin-left: auto;
  text-align: right;
  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: ${props => props.theme.colors.grey.light};
  }
`;

export default class PrevNext extends React.PureComponent {
  static propTypes = {
    prev: ArticleRef,
    next: ArticleRef,
  };

  static defaultProps = {
    prev: null,
    next: null,
  };

  /** Renderers */

  render() {
    const { next, prev } = this.props;
    return (
      <Wrapper>
        {prev && (
          <Prev>
            <span>Previous</span>
            <Link to={prev.fields.slug}>{prev.frontmatter.title}</Link>
          </Prev>
        )}

        {next && (
          <Next>
            <span>Next</span>
            <Link to={next.fields.slug}>{next.frontmatter.title}</Link>
          </Next>
        )}
      </Wrapper>
    );
  }
}

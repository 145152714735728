/**
 * Created by Giannis Kallergis on 2018-12-26
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import kebabCase from "lodash/kebabCase";
import { DiscussionEmbed } from "disqus-react";
import { lighten } from "polished";

/** Models / Types */
import { ArticleNode, ArticleRef } from "../propTypes";

/** Components */
import Layout from "../components/Layout";
import Wrapper from "../components/Wrapper";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Subline from "../components/Subline";
import PrevNext from "../components/PrevNext";
import Content from "../components/Content";
import TagsContainer from "../components/TagsContainer";
import TLDR from "../components/TLDR";
import AuthorHeader from "../components/AuthorHeader";

const Title = styled.h1`
  margin-bottom: 1rem;
`;

const PostContent = styled.div`
  margin-top: 3rem;
`;

const Spacer = styled.div`
  height: 2px;
  margin: 3em;
  background: linear-gradient(
    45deg,
    ${props => lighten(1, props.theme.colors.primary)},
    ${props => props.theme.colors.primary},
    ${props => lighten(1, props.theme.colors.primary)}
  );
`;

// noinspection JSUnusedGlobalSymbols
export default class ArticlePage extends React.PureComponent {
  static propTypes = {
    pageContext: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      next: ArticleRef,
      prev: ArticleRef,
    }),
    data: PropTypes.shape({
      markdownRemark: ArticleNode,
    }),
  };

  static defaultProps = {
    pageContext: PropTypes.shape({
      prev: null,
      next: null,
    }),
  };

  /** Renderers */

  render() {
    const { pageContext, data } = this.props;
    const { slug, prev, next } = pageContext;
    const article = data.markdownRemark;
    const id = article.id;
    const category = article.frontmatter.category;
    const title = article.frontmatter.title;
    const date = article.frontmatter.date;
    const tldr = article.frontmatter.tldr;
    const tags = article.frontmatter.tags || [];
    const timeToRead = article.fields.timeToRead;
    const disqusShortname = "jakallergis";
    const disqusConfig = { identifier: id, title };
    return (
      <Layout>
        <SEO //
          isPost
          slug={slug}
          title={title}
          description={tldr}
          date={date}
          keywords={tags}
        />
        <Wrapper>
          <Header>
            <AuthorHeader />
          </Header>
          <Content hasHeader>
            <Title>{title}</Title>
            <Subline>
              {!!date && <>{date} &mdash; </>}
              {timeToRead + " "}
              {!!category && (
                <>
                  &mdash; In <Link to={`/categories/${kebabCase(category)}`}>{category}</Link>
                </>
              )}
            </Subline>
            <TLDR tldr={tldr} />
            <PostContent dangerouslySetInnerHTML={{ __html: article.html }} />
            <TagsContainer tags={tags.map(t => ({ name: t }))} />
            <PrevNext prev={prev} next={next} />
            <Spacer />
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
export const ArticleQuery = graphql`
  query ArticleBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...ArticleInfo
    }
  }
`;

/**
 * Created by Giannis Kallergis on 2018-12-26
 */

import PropTypes from "prop-types";
import styled from "styled-components";

const Subline = styled.div`
  font-size: ${props => props.theme.fontSize.small};
  color: ${props => props.theme.colors.grey.light};
  ${props => props.sectionTitle && "margin-top: -3rem"};
  ${props => props.sectionTitle && "margin-bottom: 4rem"};
  ${props => props.sectionTitle && "text-align: center"};
`;

Subline.propTypes = {
  sectionTitle: PropTypes.bool.isRequired,
};

Subline.defaultProps = {
  sectionTitle: false,
};

export default Subline;
